export const healthFacilites = [
  {
    id: 1,
    extraLabel: "Your Challenges",
    userType: "Are you facing these challenges in your procurement?",
    description: {
      line1: `<strong className='font-bold text-black-1000'>Inefficient Process</strong> - Spend hours daily on manual order placements and status checks`,
      line2: `<strong className='font-bold text-black-1000'>Overwhelming Supplier Management</strong> - Manage too many products from different suppliers.`,
      line3: `<strong className='font-bold text-black-1000'>Inventory Issues</strong> - Deal with frequent stockouts and overstock issues.`,
      line4: `<strong className='font-bold text-black-1000 inline-block'>Supply Chain Disruptions</strong> - Lack of visibility on supply chain disruptions.`,
      line5: `<strong className='font-bold text-black-1000'>Financial Pains</strong> - Struggle to balance cost savings and quality on a tight budget.`,
      line6: `<strong className='font-bold text-black-1000'>Compliance</strong> - Unsure about suppliers’ compliance with regulations.`,
      line7: `<strong className='font-bold text-black-1000'>Sustainability Challenge</strong> - Can't trace product sources for sustainable procurement.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G6H4VzgS97iQUmWz9",
    banner: "/assets/images/medicine6b.jpg",
    banner2: "/assets/images/medicine6.jpg",
  },

  {
    id: 2,
    extraLabel: "Our Solutions",
    userType: "What EkkBaz AI Can Do",
    description: {
      line1: `<strong className='font-bold text-black-1000 style='>AI-Powered Automation</strong> - Automate your procurement process and boost efficiency by 80%.`,
      line2: `<strong className='font-bold text-black-1000'>Streamlined Supplier Management</strong> - Standardize the vendor management at the product level.`,
      line3: `<strong className='font-bold text-black-1000'>Inventory Intelligence</strong> - Integrate data to reduce revenue losses due to inventory issues.`,
      line4: `<strong className='font-bold text-black-1000'>Supply Chain Visibility</strong> - Get alerts for supply chain issues and contingency plans.`,
      line5: `<strong className='font-bold text-black-1000'>Optimal Budget Allocation</strong> - Receive automatic procurement recommendations for best ROI.`,
      line6: `<strong className='font-bold text-black-1000'>Compliance Protection</strong> - Get automatic alerts for compliance risks.`,
      line7: `<strong className='font-bold text-black-1000'>Assured Sustainability</strong> - Trace product sources to ensure sustainability.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G3fhPuDfPRhnxDre8",
    banner: "/assets/images/medicine5b.png",
    banner2: "/assets/images/medicine5.png",
  },

  {
    id: 3,
    extraLabel: "Call to Action",
    userType: "Grow Your Business",
    description: `Let us book an appointment to discuss how EkkBaz AI and help you in your procurement`,
    btnLabel: "Book an Appointment",
    formLink:
      "https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/",
    banner: "/assets/images/medicine3.png",
  },
];
