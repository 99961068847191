import React from "react";
import { useNavigate } from "react-router-dom";

const UpdateFooter = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-[#0D0D2B] text-white py-10">
      <div className="container mx-auto px-4 flex flex-wrap md:flex-nowrap justify-center gap-10 py-5">
        {/* Logo and Description (40%) */}
        <div className="flex-1 md:flex-[0.4] flex flex-col items-center md:items-start">
          <img
            src="/assets/images/LogoAI.png"
            alt="EkkBaz AI"
            className="w-40 h-16"
          />
          <p className="text-center md:text-left mt-2">
            EkkBaz AI is more than just a tool; it's your dedicated partner.
          </p>
        </div>

        {/* Other Sections (60%) */}
        <div className="lg:flex-[0.6] flex lg:flex-row flex-col items-center gap-10 justify-center md:justify-between">
          {/* Links */}
          <div className="flex flex-col items-center md:items-start justify-center w-full">
            {/* <h3 className="font-bold mb-2">Products</h3> */}
            <ul className="space-y-2">
              <li>
                <p
                  onClick={() => navigate("/pricing")}
                  className="hover:underline"
                >
                  Pricing
                </p>
              </li>
              <li>
                <p
                  onClick={() => navigate("/company/about-us")}
                  className="hover:underline"
                >
                  Company
                </p>
              </li>
            </ul>
          </div>
          {/* Social Links  */}
          <div className=" flex flex-col items-center justify-center  w-full">
            <div className="flex space-x-4 items-center">
              <a
                href="https://twitter.com/EkkBaz"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/x_update.png"
                  alt="Twitter"
                  className="w-5 h-5"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ekkbaz/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/linkedin_update.png"
                  alt="LinkedIn"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://wa.me/+6592344538"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/whatsapp_update.png"
                  alt="whatsapp"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://www.instagram.com/ekkbaz/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/instagram_update.png"
                  alt="Instagram"
                  className="w-6 h-6"
                />
              </a>
            </div>
            <p className="text-sm mt-2 mb-3">Follow our social media.</p>
            <p className="text-sm text-center">
              ©Copyright EkkBaz AI. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default UpdateFooter;
