import React from "react";
import "./style.scss";
import { motion } from "framer-motion";

export default function Banner({
  headline,
  from,
  bannerImage,
  alreadyHaveTopPadding,
}) {
  const text = headline.split(" ");
  return (
    <div
      className={` ${
        alreadyHaveTopPadding ? "lg:pt-40 pt-40 pb-24" : "lg:pt-40 pt-40 pb-24"
      } flex justify-center items-center w-full h-full bg-gradient-to-br  from-blue-900 to-blue-800 text-white`}
    >
      <h6
        className={`text-white ${
          alreadyHaveTopPadding
            ? "lg:text-4xl text-[30px]"
            : "lg:text-4xl text-[30px]"
        } font-bold`}
      >
        {headline}
      </h6>
    </div>
  );
  // return (
  //   // <div
  //   //   className={`${
  //   //     alreadyHaveTopPadding
  //   //       ? "lg:pt-24 pt-24 lg:h-[350px]"
  //   //       : "lg:pt-24 pt-24 lg:h-[350px]"
  //   //   } relative overflow-hidden bg-gradient-to-br from-blue-900 to-blue-800 text-white`}
  //   // >
  //     {/* <div
  //     className={`${
  //       from === "aboutUs"
  //         ? ""
  //         : from === "contactUs"
  //         ? ""
  //         : from === "food"
  //         ? ""
  //         : from === "electronics"
  //         ? ""
  //         : from === "health"
  //         ? ""
  //         : "image-with-filter"
  //     }`}
  //     >
  //       <img
  //         className="w-full h-full left-0 block"
  //         src={bannerImage}
  //         alt="banner"
  //       />
  //     </div> */}

  //     {
  //       <div
  //         className={`absolute ${
  //           alreadyHaveTopPadding ? "lg:pt-24 pt-24" : "lg:pt-24 pt-24"
  //         } flex justify-center items-center w-full h-full top-0 left-0 from-blue-900 to-blue-800 text-white`}
  //       >
  //         <h6
  //           className={`text-white ${
  //             alreadyHaveTopPadding
  //               ? "lg:text-[50px] text-[30px]"
  //               : "lg:text-[80px] text-[30px]"
  //           } font-bold`}
  //         >
  //           {headline}
  //         </h6>
  //       </div>
  //     }
  //   // </div>
  // );
}
