import React from "react";

const UpdateBanner = () => {
  return (
    <div className="py-24 bg-gradient-to-br from-blue-900 to-blue-800 text-white flex items-center justify-center px-8">
      <div className="max-w-5xl space-y-8 text-left">
        <div className="lg:w-1/2">
          <h1 className="text-4xl sm:text-5xl font-bold">
            Transform Your Procurement Team with Superhuman AI Agents
          </h1>
          <p className="text-md sm:text-lg leading-relaxed mt-2">
            EkkBaz AI is the groundbreaking solution that transforms procurement
            teams into a strategic powerhouse with limitless AI efficiency. Our
            AI agents are designed to streamline every aspect of your
            procurement workflows by 95%, enabling teams to operate at peak
            performance 24/7 while delivering exceptional value.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpdateBanner;
