import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export default function UpdatePricing({ from }) {
  return (
    <div className="container lg:pt-16 pt-12">
      <h6 className="mb-10 font-bold text-[#2563EB] lg:text-[30px] text-20  lg:pb-5 pb-0 pt-5 lg:pt-0 text-center">
        Choose the right plan for you
      </h6>
      <div>
        <Tabs>
          <div
            className={`price-tab-title text-center ${
              from === "health"
                ? "health-tab"
                : from === "film"
                ? "film-tab"
                : from === "electronics"
                ? "electronics-tab"
                : from === "food"
                ? "food-tab"
                : from === "hotel"
                ? "hotel-tab"
                : "health-tab"
            }`}
          >
            <TabList className="tab-title mb-[50px]">
              <Tab>Monthly</Tab>
              <Tab>Annually</Tab>
            </TabList>
          </div>

          <TabPanel>
            <div className="grid grid-cols-1 gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
              <div className="bg-white p-5 rounded-[15px] relative">
                <button
                  className={`${
                    from === "health"
                      ? "bg-[#418cfd]"
                      : from === "film"
                      ? "bg-black-1000"
                      : from === "electronics"
                      ? "bg-[#A662FF]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "hotel"
                      ? "bg-[#EE6102]"
                      : "bg-[#2563EB]"
                  } text-white font-bold text-20 rounded-[10px] py-2 px-5 mb-[30px]`}
                >
                  Basic
                </button>
                <div className="pb-[10px] border-b border-black-200">
                  <h3
                    className="font-bold text-24 md:text-30 sm:text-26 lg:text-44"
                    style={{ color: "#020202" }}
                  >
                    $199{" "}
                    <span className="text-black-500 text-22 md:text-30 sm:text-26 lg:text-36">
                      /month
                    </span>
                  </h3>
                </div>
                <h5 className="pt-[30px] text-18 md:text-22 sm:text-20 lg:text-24 pb-[6px] text-black-1000 font-bold">
                  SaaS + Remote
                </h5>
                <ul className=" pb-[180px] ml-4" style={{ listStyle: "disc" }}>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Easy to use vertical SaaS application.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Remote virtual officer support.
                    </p>
                  </li>
                </ul>

                <a
                  // href="https://forms.gle/5wZKLajuCJ3J5Ftu8"
                  href="https://ekkbaz.com/"
                  style={{ border: "1px solid rgba(0,0,0,.30)" }}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-center rounded-[10px] text-black bg-white hover:text-white ${
                    from === "health"
                      ? "hover:bg-[#418cfd]"
                      : from === "film"
                      ? "hover:bg-black-1000"
                      : from === "electronics"
                      ? "hover:bg-[#A662FF]"
                      : from === "food"
                      ? "hover:bg-[#009061]"
                      : from === "hotel"
                      ? "hover:bg-[#EE6102]"
                      : "hover:bg-[#2563EB]"
                  }  py-[18px]  font-semibold absolute w-[calc(100%-40px)] bottom-[20px]`}
                >
                  Get Started
                </a>
              </div>
              <div className="bg-white p-5 rounded-[15px] relative">
                <button
                  className={`${
                    from === "health"
                      ? "bg-[#418cfd]"
                      : from === "film"
                      ? "bg-black-1000"
                      : from === "electronics"
                      ? "bg-[#A662FF]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "hotel"
                      ? "bg-[#EE6102]"
                      : "bg-[#2563EB]"
                  } text-white font-bold text-20 rounded-[10px] py-2 px-5 mb-[30px]`}
                >
                  Professional
                </button>
                <div className="pb-[10px] border-b border-black-200">
                  <h3
                    className="font-bold text-24 md:text-30 sm:text-26 lg:text-44"
                    style={{ color: "#020202" }}
                  >
                    $1499{" "}
                    <span className="text-black-500 text-22 md:text-30 sm:text-26 lg:text-36">
                      /month
                    </span>
                  </h3>
                </div>
                <h5 className="pt-[30px] text-18 md:text-22 sm:text-20 lg:text-24 pb-[6px] text-black-1000 font-bold">
                  SaaS + Dedicated
                </h5>
                <ul className=" pb-[180px] ml-4" style={{ listStyle: "disc" }}>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Everything in Basic Tier.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Dedicated Remote virtual officer support.
                    </p>
                  </li>
                </ul>

                <a
                  // href="https://forms.gle/uW3DTihAnwVLR2mJ7"
                  href="https://ekkbaz.com/"
                  style={{ border: "1px solid rgba(0,0,0,.30)" }}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-center rounded-[10px] text-black bg-white hover:text-white ${
                    from === "health"
                      ? "hover:bg-[#418cfd]"
                      : from === "film"
                      ? "hover:bg-black-1000"
                      : from === "electronics"
                      ? "hover:bg-[#A662FF]"
                      : from === "food"
                      ? "hover:bg-[#009061]"
                      : from === "hotel"
                      ? "hover:bg-[#EE6102]"
                      : "hover:bg-[#2563EB]"
                  }  py-[18px]  font-semibold absolute w-[calc(100%-40px)] bottom-[20px]`}
                >
                  Get Started
                </a>
              </div>{" "}
              <div className="bg-white p-5 rounded-[15px] relative">
                <button
                  className={`${
                    from === "health"
                      ? "bg-[#418cfd]"
                      : from === "film"
                      ? "bg-black-1000"
                      : from === "electronics"
                      ? "bg-[#A662FF]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "hotel"
                      ? "bg-[#EE6102]"
                      : "bg-[#2563EB]"
                  } text-white font-bold text-20 rounded-[10px] py-2 px-5 mb-[30px]`}
                >
                  Custom
                </button>
                <div className="pb-[10px] border-b border-black-200">
                  <h3
                    className="font-bold text-24 md:text-30 sm:text-26 lg:text-44"
                    style={{ color: "#020202" }}
                  >
                    Let’s Talk!
                  </h3>
                </div>
                <h5 className="pt-[30px] text-18 md:text-22 sm:text-20 lg:text-24 pb-[6px] text-black-1000 font-bold">
                  AI Agent
                </h5>
                <p className="font-semibold text-16 text-black-500 pb-[180px]">
                  Generative AI powered AI Agent to automate your in-house
                  procurement workflows.
                </p>
                <a
                  href="https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/"
                  style={{ border: "1px solid rgba(0,0,0,.30)" }}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-center rounded-[10px] text-black bg-white hover:text-white ${
                    from === "health"
                      ? "hover:bg-[#418cfd]"
                      : from === "film"
                      ? "hover:bg-black-1000"
                      : from === "electronics"
                      ? "hover:bg-[#A662FF]"
                      : from === "food"
                      ? "hover:bg-[#009061]"
                      : from === "hotel"
                      ? "hover:bg-[#EE6102]"
                      : "hover:bg-[#2563EB]"
                  }  py-[18px]  font-semibold absolute w-[calc(100%-40px)] bottom-[20px]`}
                >
                  Book a Call
                </a>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="grid grid-cols-1 gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
              <div className="bg-white p-5 rounded-[15px] relative ">
                <button
                  className={` bg-success  text-white font-semibold text-14 rounded-tl-none rounded-br-none rounded-[10px] py-1 px-3 absolute top-0 right-0`}
                >
                  Save 50%
                </button>
                <button
                  className={`${
                    from === "health"
                      ? "bg-[#418cfd]"
                      : from === "film"
                      ? "bg-black-1000"
                      : from === "electronics"
                      ? "bg-[#A662FF]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "hotel"
                      ? "bg-[#EE6102]"
                      : "bg-[#2563EB]"
                  } text-white font-bold text-20 rounded-[10px] py-2 px-5 mb-[30px]`}
                >
                  Basic
                </button>

                <div className="pb-[10px] border-b border-black-200">
                  <h3
                    className="font-bold text-24 md:text-30 sm:text-26 lg:text-44"
                    style={{ color: "#020202" }}
                  >
                    $99{" "}
                    <span className="text-black-500 text-22 md:text-30 sm:text-26 lg:text-36">
                      /month
                    </span>
                  </h3>
                </div>
                <h5 className="pt-[30px] text-18 md:text-22 sm:text-20 lg:text-24 pb-[6px] text-black-1000 font-bold">
                  SaaS + Remote
                </h5>
                <ul className=" pb-[180px] ml-4" style={{ listStyle: "disc" }}>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Easy to use vertical SaaS application.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Remote virtual officer support.
                    </p>
                  </li>
                </ul>
                <a
                  // href="https://forms.gle/5wZKLajuCJ3J5Ftu8"
                  href="https://ekkbaz.com/"
                  style={{ border: "1px solid rgba(0,0,0,.30)" }}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-center rounded-[10px] text-black bg-white hover:text-white ${
                    from === "health"
                      ? "hover:bg-[#418cfd]"
                      : from === "film"
                      ? "hover:bg-black-1000"
                      : from === "electronics"
                      ? "hover:bg-[#A662FF]"
                      : from === "food"
                      ? "hover:bg-[#009061]"
                      : from === "hotel"
                      ? "hover:bg-[#EE6102]"
                      : "hover:bg-[#2563EB]"
                  }  py-[18px]  font-semibold absolute w-[calc(100%-40px)] bottom-[20px]`}
                >
                  Get Started
                </a>
              </div>
              <div className="bg-white p-5 rounded-[15px] relative">
                <button
                  className={` bg-success  text-white font-semibold text-14 rounded-tl-none rounded-br-none rounded-[10px] py-1 px-3 absolute top-0 right-0`}
                >
                  Save 33%
                </button>
                <button
                  className={`${
                    from === "health"
                      ? "bg-[#418cfd]"
                      : from === "film"
                      ? "bg-black-1000"
                      : from === "electronics"
                      ? "bg-[#A662FF]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "hotel"
                      ? "bg-[#EE6102]"
                      : "bg-[#2563EB]"
                  } text-white font-bold text-20 rounded-[10px] py-2 px-5 mb-[30px]`}
                >
                  Professional
                </button>
                <div className="pb-[10px] border-b border-black-200">
                  <h3
                    className="font-bold text-24 md:text-30 sm:text-26 lg:text-44"
                    style={{ color: "#020202" }}
                  >
                    $999{" "}
                    <span className="text-black-500 text-22 md:text-30 sm:text-26 lg:text-36">
                      /month
                    </span>
                  </h3>
                </div>
                <h5 className="pt-[30px] text-18 md:text-22 sm:text-20 lg:text-24 pb-[6px] text-black-1000 font-bold">
                  SaaS + Dedicated
                </h5>
                <ul className=" pb-[180px] ml-4" style={{ listStyle: "disc" }}>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Everything in Basic Tier.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <p className="font-semibold text-16 text-black-500">
                      Dedicated Remote virtual officer support.
                    </p>
                  </li>
                </ul>
                <a
                  // href="https://forms.gle/uW3DTihAnwVLR2mJ7"
                  href="https://ekkbaz.com/"
                  style={{ border: "1px solid rgba(0,0,0,.30)" }}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-center rounded-[10px] text-black bg-white hover:text-white ${
                    from === "health"
                      ? "hover:bg-[#418cfd]"
                      : from === "film"
                      ? "hover:bg-black-1000"
                      : from === "electronics"
                      ? "hover:bg-[#A662FF]"
                      : from === "food"
                      ? "hover:bg-[#009061]"
                      : from === "hotel"
                      ? "hover:bg-[#EE6102]"
                      : "hover:bg-[#2563EB]"
                  }  py-[18px]  font-semibold absolute w-[calc(100%-40px)] bottom-[20px]`}
                >
                  Get Started
                </a>
              </div>{" "}
              <div className="bg-white p-5 rounded-[15px] relative">
                <button
                  className={`${
                    from === "health"
                      ? "bg-[#418cfd]"
                      : from === "film"
                      ? "bg-black-1000"
                      : from === "electronics"
                      ? "bg-[#A662FF]"
                      : from === "food"
                      ? "bg-[#009061]"
                      : from === "hotel"
                      ? "bg-[#EE6102]"
                      : "bg-[#2563EB]"
                  } text-white font-bold text-20 rounded-[10px] py-2 px-5 mb-[30px]`}
                >
                  Custom
                </button>
                <div className="pb-[10px] border-b border-black-200">
                  <h3
                    className="font-bold text-24 md:text-30 sm:text-26 lg:text-44"
                    style={{ color: "#020202" }}
                  >
                    Let’s Talk!
                  </h3>
                </div>
                <h5 className="pt-[30px] text-18 md:text-22 sm:text-20 lg:text-24 pb-[6px] text-black-1000 font-bold">
                  AI Agent
                </h5>
                <p className="font-semibold text-16 text-black-500 pb-[180px]">
                  Generative AI powered AI Agent to automate your in-house
                  procurement workflows.
                </p>
                <a
                  href="https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/"
                  style={{ border: "1px solid rgba(0,0,0,.30)" }}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-center rounded-[10px] text-black bg-white hover:text-white ${
                    from === "health"
                      ? "hover:bg-[#418cfd]"
                      : from === "film"
                      ? "hover:bg-black-1000"
                      : from === "electronics"
                      ? "hover:bg-[#A662FF]"
                      : from === "food"
                      ? "hover:bg-[#009061]"
                      : from === "hotel"
                      ? "hover:bg-[#EE6102]"
                      : "hover:bg-[#2563EB]"
                  }  py-[18px]  font-semibold absolute w-[calc(100%-40px)] bottom-[20px]`}
                >
                  Book a Call
                </a>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}
