import React from "react";
import FacilityCard from "./FacilityCard";

const FacilitiesCard = ({ data }) => {
  return (
    <div className="grid md:grid-cols-3 gap-6 py-12 px-4 md:px-8 container">
      {data.map((item, index) => (
        <FacilityCard
          key={item.id}
          index={index} // Use the array index here
          extraLabel={item.extraLabel}
          userType={item.userType}
          description={item.description}
          banner={item.banner}
        />
      ))}
    </div>
  );
};

export default FacilitiesCard;
