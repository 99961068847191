import React from "react";

export default function SocialMedia() {
  return (
    <div className="grid lg:grid-cols-5 grid-cols-1 gap-5 container lg:mt-16 mt-5 lg:mb-28 mb-20 ">
      <a
        href="https://www.facebook.com/ekkbaz/"
        target="_blank"
        className=" bg-white items-center justify-center flex flex-col py-3 rounded-lg shadow-sm"
        rel="noreferrer"
      >
        <img
          className="w-[50px] h-[50px] zoom-image transform transition-transform hover:scale-110"
          src="/assets/images/facebook_new.png"
          alt=""
        />
        <p className="mt-2.5 font-semibold">Facebook</p>
      </a>
      <a
        href="https://twitter.com/EkkBaz"
        target="_blank"
        className="bg-white items-center justify-center flex flex-col py-3 rounded-lg shadow-sm"
        rel="noreferrer"
      >
        <img
          className="w-[50px] h-[50px] zoom-image transform transition-transform hover:scale-110"
          src="/assets/images/twitter_new.png"
          alt=""
        />
        <p className="mt-2.5 font-semibold">Twitter</p>
      </a>
      <a
        href="https://www.linkedin.com/company/7603237/admin/"
        target="_blank"
        className="bg-white items-center justify-center flex flex-col py-3 rounded-lg shadow-sm"
        rel="noreferrer"
      >
        <img
          className="w-[50px] h-[50px] zoom-image transform transition-transform hover:scale-110"
          src="/assets/images/linkedin_new.png"
          alt=""
        />
        <p className="mt-2.5 font-semibold">Linkedin</p>
      </a>
      <a
        href="https://www.instagram.com/ekkbaz/"
        target="_blank"
        className="bg-white items-center justify-center flex flex-col py-3 rounded-lg shadow-sm"
        rel="noreferrer"
      >
        <img
          className="w-[50px] h-[50px] zoom-image transform transition-transform hover:scale-125 scale-110"
          src="/assets/images/instagram_new.png"
          alt=""
        />
        <p className="mt-2.5 font-semibold">Instagram</p>
      </a>
      <a
        href="https://www.youtube.com/c/EkkBaz"
        target="_blank"
        className="bg-white items-center justify-center flex flex-col py-3 rounded-lg shadow-sm"
        rel="noreferrer"
      >
        <img
          className="w-[50px] h-[50px] zoom-image transform transition-transform hover:scale-110"
          src="/assets/images/youtube_new.png"
          alt=""
        />
        <p className="mt-2.5 font-semibold">Youtube</p>
      </a>
    </div>
  );
}
