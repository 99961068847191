import Menu from "assets/images/icon/svg/Menu";
import { Link, useNavigate } from "react-router-dom";
import "./updateStyle.scss";
import Cross from "assets/images/icon/svg/Cross";
import { useState } from "react";
import CustomSelectNavigation from "./CustomSelectNavigation";

export default function UpdateNavbar({ scrolling, from }) {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const industryOptions = [
    { label: "Consumer Goods", path: "/industry/c-goods" },
    { label: "Electronics", path: "/industry/electronics" },
    { label: "Film", path: "/industry/film" },
    { label: "Healthcare", path: "/industry/healthcare" },
    { label: "Hotel", path: "/industry/hotel" },
  ];
  const companyOptions = [
    { label: "About Us", path: "/company/about-us" },
    { label: "Our Impact", path: "/company/our-impact" },
    { label: "Our News", path: "/company/our-news" },
    { label: "Contact Us", path: "/company/contact-us" },
  ];
  const productsOptions = [
    { label: "AR Automation", path: "/product/ar-automation" },
    { label: "B2B Marketplace", path: "/product/b2b" },
    { label: "Customer Management", path: "/product/customer" },
    { label: "Delivery Management", path: "/product/delivery" },
    { label: "Distributor Management", path: "/product/distributor" },
    { label: "Embedded Financing", path: "/product/financing" },
    { label: "Inventory Management", path: "/product/inventory" },
    { label: "Salesforce Automation", path: "/product/salesforce" },
    { label: "Supplier Management", path: "/product/supplier" },
    { label: "Warehouse Management", path: "/product/warehouse" },
  ];

  return (
    <>
      <div>
        <div className={`fixed top-0 z-50 w-full bg-primary3`}>
          {/* Navigation for web */}
          <div
            className={`container hidden lg:flex py-3 ${
              scrolling ? "bg-primary3" : "lg:bg-transparent bg-primary3"
            }`}
          >
            <div>
              <Link to="/">
                <img
                  src={
                    from === "mainLanding"
                      ? "/assets/images/LogoAI.png"
                      : "/assets/images/LogoAI.png"
                  }
                  alt="logo"
                  className="w-[145px] mr-10 xl:mr-20"
                />
              </Link>
            </div>
            <div className="flex items-center justify-between w-full">
              <ul className="flex items-center header-menu-items">
                <li>
                  <CustomSelectNavigation
                    options={productsOptions}
                    title="Products"
                    width="250px"
                  />
                </li>
                <li>
                  <CustomSelectNavigation
                    options={industryOptions}
                    title="Industry"
                  />
                </li>
                <li>
                  <div
                    className="cursor-pointer p-[10px] text-22 text-white mr-1"
                    onClick={() => {
                      navigate("/pricing");
                    }}
                  >
                    Pricing
                  </div>
                </li>
                <li>
                  <CustomSelectNavigation
                    options={companyOptions}
                    title="Company"
                  />
                </li>
              </ul>
            </div>
          </div>
          {/* Navigation for mobile devices */}
          <div className={`lg:hidden`}>
            <div
              className={`${
                scrolling ? "mt-0 py-2" : "mt-0 "
              } justify-between flex lg:hidden py-3 lg:py-0 bg-primary3`}
            >
              <div>
                <Link to="/">
                  <img
                    src={
                      from === "mainLanding"
                        ? "/assets/images/LogoAI.png"
                        : "/assets/images/LogoAI.png"
                    }
                    alt="logo"
                    className="sm:w-[145px] w-[100px] mr-[80px] pl-2"
                  />
                </Link>
              </div>
              <div className="flex items-center pr-2">
                <div onClick={() => setShowMenu(!showMenu)}>
                  {showMenu ? (
                    <Cross color={`#ffff`} />
                  ) : (
                    <Menu color={`#ffff`} />
                  )}
                </div>
              </div>
            </div>
            {/* Menu's div for mobile versions  */}
            <div
              className={`bg-primary3 z-50 lg:top-[80px] right-5 p-[20px] rounded-[5px] transition-all ${
                showMenu ? "absolute" : "hidden"
              }`}
            >
              <div className="py-1">
                <ul>
                  <li className="mr-4 xl:mr-5">
                    <CustomSelectNavigation
                      options={productsOptions}
                      title="Products"
                      width="250px"
                    />
                  </li>
                  <li>
                    <CustomSelectNavigation
                      options={industryOptions}
                      title="Industry"
                    />
                  </li>
                  <li className="pl-1.5 lg:pl-0">
                    <div
                      className="cursor-pointer p-[10px] text-22 text-white"
                      onClick={() => {
                        navigate("/pricing");
                      }}
                    >
                      Pricing
                    </div>
                  </li>
                  <li className="mr-4 xl:mr-5">
                    <CustomSelectNavigation
                      options={companyOptions}
                      title="Company"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
