import React from "react";
import UpdateNavbar from "../Common/UpdateNavbar";
import UpdateFooter from "../Common/UpdateFooter";
import UpdatePartners from "../Common/UpdatePartners";
import UpdatePricing from "./UpdatePricing";
import Banner from "pages/Landing/common/Banner";

export default function UpdatePricingPage() {
  return (
    <div className="">
      <UpdateNavbar />
      {/* <img
        src="/assets/images/pricing-banner.png"
        alt=""
        className=" w-full"
      /> */}
      <Banner
          headline="Pricing"
          bannerImage="/assets/images/warehouse-banner.jpeg"
          from="health"
        />
      <UpdatePricing />
      <UpdatePartners />
      <UpdateFooter />
    </div>
  );
}
