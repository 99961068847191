import React from "react";

const ConsultationSection = () => {
  return (
    <div className="flex flex-col items-center justify-center py-12 px-6">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-900 text-center">
        Let’s Set Up a Consultation
      </h2>
      <p className="mt-4 text-gray-700 text-center text-lg md:text-xl">
        EkkBaz AI is more than just a tool, it’s your dedicated partner. <br />
        Our AI agents work collaboratively with your team...
      </p>
      <button
        onClick={() =>
          window.open(
            "https://outlook.office365.com/book/AIAgentforBusiness@EkkBaz.com/",
            "_blank",
            "noopener noreferrer"
          )
        }
        className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-6 rounded-full shadow-lg"
      >
        Book a Demo →
      </button>
    </div>
  );
};

export default ConsultationSection;
