import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import Partner from "../common/Partner";
import PricePlan from "../common/PricePlan";
import { b2bData } from "./b2bdata";
import ProductFacilites from "../common/ProductFacilities";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import FacilitiesCard from "pages/UpdatedLanding/Common/FacilitiesCard";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function LandingForB2BMarketplace() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      const currentPosition = document.documentElement.scrollTop;
      setScrolling(currentPosition > 150);
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="B2B Marketplace"
        description="Handle your B2B Marketplace with AI Agent."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/b2b`}
      />
      {/* <BazAINavigation
				scrolling={scrolling ? "isScrolling" : ""}
				from='mainLanding'
			/> */}
      <UpdateNavbar />

      <Banner
        headline="B2B Marketplace"
        bannerImage="/assets/images/b2b_banner.jpg"
        from="health"
      />

      {/* <ProductFacilites facilities={b2bData} from='food' /> */}
      <div className="my-10">
        <FacilitiesCard data={b2bData} />
      </div>
      {/* <div className="my-16">
        <Partner />
      </div> */}
      {/* <PricePlan from='food' /> */}
      {/* <FooterV2 /> */}
      <UpdateFooter />
    </>
  );
}
