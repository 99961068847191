import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function CustomSelectNavigation({ options, title, width }) {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (path) => {
    navigate(path);
    setShowOptions(false);
  };

  return (
    <div className="relative " ref={dropdownRef}>
      <div
        className="cursor-pointer py-[10px] px-4 text-22 text-white flex items-center"
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        {title}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 ml-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>

      {showOptions && (
        <div
          className={`absolute top-[100%]  bg-primary3 z-[1000] ${
            width ? "lg:left-0 left-[-100px]" : "left-0"
          }`}
          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
        >
          {options.map((option) => (
            <div
              key={option.path}
              className={`py-[8px] px-[20px] cursor-pointer hover:bg-[#171d8b] text-white ${
                width ? `lg:w-[${width}] w-[230px]` : "w-[165px]"
              }`}
              style={{ transition: "background-color 1s ease" }}
              onClick={() => handleOptionClick(option.path)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
