import React from "react";

const FacilityCard = ({ extraLabel, userType, description, banner, index }) => {
  return (
    <div
      className={`${
        index % 2 !== 0 ? "bg-[#E7EAFF]" : "bg-white"
      } shadow-lg rounded-xl overflow-hidden max-w-md mx-auto`}
    >
      <div className="p-4">
        <h3 className="font-semibold text-gray-800 text-30">{userType}</h3>
        <p className="text-gray-600 mt-2 text-20">{description}</p>
      </div>
      {/* <div className="flex justify-center py-4">
        <button className="text-blue-600 hover:text-blue-800 transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12.75L12 19.5m0 0l-7.5-6.75M12 19.5V4.5"
            />
          </svg>
        </button>
      </div> */}
    </div>
  );
};

export default FacilityCard;
