import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";

const EnterpriseSolutions = () => {
  const [playing1, setPlaying1] = useState(true);
  const [playing2, setPlaying2] = useState(true);
  const [playing3, setPlaying3] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const sliderRef = useRef();
  // Slider settings
  const videoLoading = () => {
    setLoading(true);
    let interval = setTimeout(() => {
      clearTimeout(interval);
      setLoading(false);
    }, 400);
  };
  useEffect(() => {
    let interval = setTimeout(() => {
      clearTimeout(interval);
      setMainLoading(false);
    }, 2000);
    return () => clearTimeout(interval);
  }, []);
  const settings = {
    dots: false,
    dotsClass: "slick-dots customer custom-slick-dots",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    adaptiveHeight: true,
    appendDots: (dots) => <ul onClick={videoLoading}>{dots}</ul>,
  };

  const next = () => {
    videoLoading();
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    videoLoading();
    sliderRef.current?.slickPrev();
  };

  return (
    <section className="container py-10">
      <div className="">
        <Slider {...settings} ref={sliderRef} onSwipe={() => videoLoading()}>
          {/* Slide 1 */}
          <div className="relative">
            <div className="lg:w-1/2 w-full lg:p-6 lg:py-16">
              <h2 className="text-4xl font-bold text-black leading-tight ">
                For your <span className="text-[#3B82F6]">Enterprise</span> & {" "}
                <span className="text-[#3B82F6]">SMB Solutions</span>
              </h2>
              <p className="mt-4 text-gray-600">
                Empower your enterprise and SMB operations with tailored
                solutions designed for scalability and efficiency. Our tools
                simplify workflows, optimize performance, and help businesses
                stay competitive in evolving markets.
              </p>
              {/* <button className="mt-6 px-6 py-3 text-[#2563EB] bg-white rounded-full shadow-lg hover:bg-[#2563EB] hover:text-white font-bold">
                Learn more
              </button> */}
            </div>
            <div className="lg:absolute lg:left-[50%] lg:top-0 relative">
              <ReactPlayer
                url="https://ekkfilesdbt.blob.core.windows.net/userssblobs/af2f5060-4bc1-45ee-8859-6b69531a5354baz_ai_smb.mp4"
                width="100%"
                // height="518px"
                controls
                light={<img src="/assets/video/baz_ai_smb.png" alt="Thumbnail" />}
                playing={playing1}
                playIcon={
                  <button className="absolute lg:top-[42%] lg:left-[42%]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="90"
                      height="90"
                      viewBox="0 0 90 90"
                      fill="none"
                    >
                      <circle
                        cx="45"
                        cy="45"
                        r="43.75"
                        stroke="white"
                        strokeWidth="2.5"
                      />
                      <path
                        d="M61 43.2679C62.3333 44.0377 62.3333 45.9622 61 46.732L38.5 59.7224C37.1667 60.4922 35.5 59.53 35.5 57.9904L35.5 32.0096C35.5 30.47 37.1667 29.5078 38.5 30.2776L61 43.2679Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                }
              />
            </div>
          </div>

          {/* Slide 2 */}
          <div className="relative">
            <div className="lg:w-1/2 w-full lg:p-6 pb-5 lg:py-20">
              <h2 className="text-4xl font-bold text-black leading-tight">
                Simplify Your <span className="text-[#3B82F6]">Workflow</span>
              </h2>
              <p className="mt-4 text-gray-600">
                Experience seamless integration and enhanced performance for
                your business operations, ensuring steady growth and efficiency
                at every step.
              </p>
              {/* <button className="mt-6 px-6 py-3 text-[#2563EB] bg-white rounded-full shadow-lg hover:bg-[#2563EB] hover:text-white font-bold">
                Learn more
              </button> */}
            </div>
            <div className="lg:absolute lg:top-0 lg:left-1/2 relative">
              <ReactPlayer
                url="https://ekkfilesdbt.blob.core.windows.net/userssblobs/629f60e6-cc9b-474e-ad65-3647a9c0dd54baz_ai_enterprise.mp4"
                width="100%"
                // height="500px"
                controls
                light={<img src="/assets/video/baz_ai_enterprise.png" alt="Thumbnail" />}
                playing={playing2}
                playIcon={
                  <button className="absolute lg:top-[42%] lg:left-[42%]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="90"
                      height="90"
                      viewBox="0 0 90 90"
                      fill="none"
                    >
                      <circle
                        cx="45"
                        cy="45"
                        r="43.75"
                        stroke="white"
                        strokeWidth="2.5"
                      />
                      <path
                        d="M61 43.2679C62.3333 44.0377 62.3333 45.9622 61 46.732L38.5 59.7224C37.1667 60.4922 35.5 59.53 35.5 57.9904L35.5 32.0096C35.5 30.47 37.1667 29.5078 38.5 30.2776L61 43.2679Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                }
              />
            </div>
          </div>
        </Slider>
      </div>
      <div className="flex justify-center lg:pt-4">
        <div className="arrows mr-5" onClick={previous}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 70 70"
            fill="none"
          >
            <circle
              cx="35"
              cy="35"
              r="33.75"
              stroke="#3B82F6"
              strokeWidth="2.5"
            />
            <path
              d="M22.0833 35.4574L47.0833 35.4574"
              stroke="#3B82F6"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.1663 45.4979L22.083 35.4579L32.1663 25.4163"
              stroke="#3B82F6"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="arrows " onClick={next}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 70 70"
            fill="none"
          >
            <circle
              cx="35"
              cy="35"
              r="33.75"
              stroke="#3B82F6"
              strokeWidth="2.5"
            />
            <path
              d="M47.9167 34.5426H22.9167"
              stroke="#3B82F6"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.8337 24.5021L47.917 34.5421L37.8337 44.5837"
              stroke="#3B82F6"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default EnterpriseSolutions;
