import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import UserFacilites from "../common/UserFacilities";
import { electronicsFacilities } from "./ElectronicsFacilities";
import Partner from "../common/Partner";
import PricePlan from "../common/PricePlan";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function LandingForElectronics() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="AI Agent for Electronics"
        description="AI Agent for Electronics Industry."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/electronics`}
      />
      {/* <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      /> */}
      <UpdateNavbar />

      <Banner
        headline="Sustainable Electronics"
        bannerImage="/assets/images/electronics_banner.jpeg"
        from="electronics"
      />

      <UserFacilites facilities={electronicsFacilities} from="electronics" />
      <div className="my-16">
        <Partner />
      </div>
      {/* <PricePlan from="electronics" /> */}
      {/* <ClientsReview /> */}

      {/* <FooterV2 /> */}
      <UpdateFooter/>
    </>
  );
}
