import React, { useEffect, useState } from "react";

import SingleBlog from "./SingleBlog";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper";

import { Navigation } from "swiper";

import "swiper/css";

import "swiper/css/pagination";

import "swiper/css/navigation";

import "./style.scss";

import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import BlogData from "services/Blog";
import SectionHeader from "./SectionHeader";

export default function Blog({ items }) {
  const [blogList, setBlogList] = useState([]);
  const getBlogList = async (ctx) => {
    let blogList = await BlogData.getLatestJobList();
    if (blogList.status === 200) {
      console.log(blogList);
      setBlogList(blogList.results);
    }
  };

  useEffect(() => {
    getBlogList("Latest News");
  }, []);
  const [post, setPost] = useState([]);
  const ekkLanguage = useSelector((state) => state.lan.lan);

  const navigate = useNavigate();

  return (
    <div className="container m-auto  pb-[20px] md:pb-[20px] lg:pb-[120px] bg-F2F3F7 ">
      {/* <SectionHeader title={ekkLanguage.landing.discover} /> */}
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
        {blogList?.map((el, i) => {
          return (
            // <SwiperSlide key={i}>
            <SingleBlog item={el} key={i} />
            // </SwiperSlide>
          );
        })}
      </div>

      <div className="blog-wrapper">
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{ clickable: true }}
          // navigation={true}

          spaceBetween={40}
          autoplay={true}
          breakpoints={{
            280: {
              slidesPerView: 1,
            },

            360: {
              slidesPerView: 1,
            },

            540: {
              slidesPerView: 2,
            },

            991: {
              slidesPerView: 3,
            },
          }}

          // onSlideChange={() => console.log("slide change")}

          // onSwiper={(swiper) => console.log(swiper)}
        >
          {/* {Array.isArray(items) &&
						items.map((el, i) => {
							return (
								<SwiperSlide key={i}>
									<SingleBlog item={el} />
								</SwiperSlide>
							);
						})} */}
        </Swiper>

        <div className="text-center pt-14 lg:mb-0 mb-10">
          <Link
            to="/post/en"
            className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn`}
          >
            {ekkLanguage.landing.seeMore}
          </Link>
        </div>
      </div>
    </div>
  );
}
