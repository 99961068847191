import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import Partner from "../common/Partner";
import PricePlan from "../common/PricePlan";
import BazAINavigation from "../common/BazAINavigation";
import UserFacilites from "../common/UserFacilities";
import { healthFacilites } from "./healthFacilites";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import { Helmet } from "react-helmet-async";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function LandingForHealth() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="AI Agent for Healthcare"
        description="AI Agent for Healthcare Industry."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/healthcare`}
      />
      {/* <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      /> */}
      <UpdateNavbar />

      <Banner
        headline="Healthcare"
        bannerImage="/assets/images/banner_bg_3.jpeg"
        from="health"
      />

      <UserFacilites facilities={healthFacilites} from="health" />
      <div className="my-16">
        <Partner />
      </div>
      {/* <PricePlan from="health" /> */}

      {/* <FooterV2 /> */}
      <UpdateFooter />
    </>
  );
}
