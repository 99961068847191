import React, { useEffect, useState } from "react";
import BazAINavigation from "../common/BazAINavigation";
import FooterV2 from "../common/FooterV2";
import Banner from "../common/Banner";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import CustomerImpact from "../Home/CustomerImpact";
import BazAiScreenShot from "../Home/BazAiScreenShot";
import Blog from "../Home/Blog";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function OurNewsPage() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  return (
    <>
      <BazAIHelmet
        title="EkkBaz AI"
        description="Transforming businesses with AI Agents."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/company/our-impact`}
      />
      {/* <BazAINavigation
				scrolling={scrolling ? "isScrolling" : ""}
				from='mainLanding'
			/> */}
      <UpdateNavbar />
      <div className="">
        <Banner
          headline="Our News"
          bannerImage="/assets/images/aboutUs.jpeg"
          from="aboutUs"
          alreadyHaveTopPadding={true}
        />
      </div>
      <div className="lg:mt-10">
        <Blog />
      </div>

      {/* <FooterV2 /> */}
      <UpdateFooter />
    </>
  );
}
