import React, { useEffect, useState } from "react";
import BazAINavigation from "../common/BazAINavigation";
import OurTeam from "./OurTeam";
import FooterV2 from "../common/FooterV2";
import Banner from "../common/Banner";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import Partners from "../Home/Partners";
import SectionHeader from "../Home/SectionHeader";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function AboutUsPage() {
  const [teams] = useState([
    {
      image: "/assets/images/team1.png",
      name: "Enam Chowdhury",
      linkDin: "https://www.linkedin.com/in/enamanc/",
      x: "https://twitter.com/enamanc",
      fb: "https://www.facebook.com/enamanc",
      logo: "/assets/images/team1_logo.png",
    },
    {
      image: "/assets/images/team2.png",
      name: "Zobaida Sultana",
      linkDin: "https://www.linkedin.com/in/zobaida-s-59374a13/",
      x: "https://twitter.com/Zobaidachowdhur",
      fb: "https://www.facebook.com/zschowdhury?mibextid=kFxxJD",
      logo: "/assets/images/team2_logo.png",
    },
    {
      image: "/assets/images/team3.png",
      name: "Jimmy Guo",
      linkDin: "https://www.linkedin.com/in/jimmyjcguo/",
      x: "",
      fb: "",
      logo: "/assets/images/team3_logo.png",
    },
    {
      image: "/assets/images/team4.png",
      name: "Towfiq Ahmed",
      linkDin: "https://www.linkedin.com/in/towfiq-ahmed-90a2687b/",
      x: "",
      fb: "https://www.facebook.com/towfiq.a.chowdhury?mibextid=eQY6cl ",
      logo: "/assets/images/team4_logo.png",
    },
  ]);
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  return (
    <>
      <BazAIHelmet
        title="EkkBaz AI"
        description="Transforming businesses with AI Agents."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/company/about-us`}
      />
      {/* <BazAINavigation
				scrolling={scrolling ? "isScrolling" : ""}
				from='mainLanding'
			/> */}
      <UpdateNavbar />
      <div className="">
        <Banner
          headline="About Us"
          bannerImage="/assets/images/aboutUs.jpeg"
          from="aboutUs"
          alreadyHaveTopPadding={true}
        />
      </div>
      {/* <OurTeam teams={teams} /> */}
      <div className="container m-auto pb-[40px] md:pb-[70px] lg:pb-[70px] bg-F2F3F7">
        {/* <SectionHeader title="About Us" /> */}
        <div className="text-center mt-10">
          <h5 className="font-medium lg:text-22 text-14 text-[#00000080] mt-2 text-center">
            EkkBaz.AI is on a mission to redefine procurement through
            cutting-edge AI technology. Our team consists of industry veterans
            and AI experts committed to driving transformation and maximizing
            operational efficiency.
          </h5>
          <h6 className="font-bold mt-6 lg:text-22 text-16  text-[#00000080] text-center">
            Join us as we lead the future of procurement.
          </h6>
        </div>
      </div>
      <Partners />
      {/* <FooterV2 /> */}
      <UpdateFooter />
    </>
  );
}
