import React from "react";
import Xarrow from "react-xarrows";
import "./ProcurementChallenges.scss";

const challenges = [
  {
    id: 1,
    title: "Inefficient Workflow",
    description:
      "Excessive manual effort in sourcing, onboarding, and managing stakeholders (e.g., Suppliers, Distributors).",
    icon: "🛠️",
    position: { top: "20%", left: "30%" },
  },
  {
    id: 2,
    title: "Sustainability and Compliance",
    description:
      "Growing pressure to adopt sustainability practices and meet regulatory requirements.",
    icon: "🌱",
    position: { top: "20%", left: "70%" },
  },
  {
    id: 3,
    title: "Supply Chain Disruptions",
    description:
      "Struggle to handle disruptions due to unforeseen issues like geopolitical tensions and natural disasters.",
    icon: "🚨",
    position: { top: "50%", left: "85%" },
  },
  {
    id: 4,
    title: "Change Management Issue",
    description:
      "Any modifications to product designs lead to extensive communications with suppliers.",
    icon: "🔄",
    position: { top: "80%", left: "70%" },
  },
  {
    id: 5,
    title: "Lack of Insights",
    description:
      "Fragmented IT systems hinder the ability to obtain valuable insights to make informed decisions.",
    icon: "📊",
    position: { top: "80%", left: "30%" },
  },
  {
    id: 6,
    title: "Budget and Spend Management",
    description:
      "Challenges in controlling budgets and expenditures are heightened by increasing supplier risks.",
    icon: "💰",
    position: { top: "50%", left: "15%" },
  },
];

const ProcurementChallenges = () => {
  return (
    <div>
      <h1 className="font-semibold text-4xl text-center pt-20">
        Key <span className="text-[#2563EB]">Procurement</span> Challenges
      </h1>

      <h1 className="lg:hidden block font-semibold text-xl px-4 text-center mt-5">
        How Our <span className="text-[#2563EB]">AI Solutions</span> Can
        Redefine Your <span className="text-[#2563EB]">Growth Strategy</span>
      </h1>

      {/* Grid layout for small devices */}
      <div className="lg:hidden grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-6 mx-auto">
        {challenges.map((challenge) => (
          <div
            key={challenge.id}
            className="challenge-card"
            style={{
              background:
                "linear-gradient(195.72deg, #F4F8FF 6.73%, #ECF8FF 89.02%)",
              borderRadius: "10px",
              padding: "15px",
              textAlign: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="challenge-icon">{challenge.icon}</div>
            <h2 className="challenge-title">{challenge.title}</h2>
            <p className="challenge-description">{challenge.description}</p>
          </div>
        ))}
      </div>

      {/* Central Node and Arrows for larger screens */}
      <div className="challenges-container hidden lg:block">
        <div
          id="center-node"
          className="central-node"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1 className="header-title">
            How Our <span>AI Solutions</span> Can Redefine Your{" "}
            <span>Growth Strategy</span>
          </h1>
        </div>

        {/* Challenge Cards */}
        {challenges.map((challenge) => (
          <div
            key={challenge.id}
            id={`${challenge.id}`}
            className="challenge-card"
            style={{
              position: "absolute",
              top: challenge.position.top,
              left: challenge.position.left,
              transform: "translate(-50%, -50%)",
              background:
                "linear-gradient(195.72deg, #F4F8FF 6.73%, #ECF8FF 89.02%)",
              borderRadius: "10px",
              padding: "15px",
              width: "200px",
              textAlign: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="challenge-icon">{challenge.icon}</div>
            <h2 className="challenge-title">{challenge.title}</h2>
            <p className="challenge-description">{challenge.description}</p>
          </div>
        ))}

        {/* Arrows */}
        {challenges.map((challenge) => {
          let endAnchor = "middle";
          if (challenge.position.top === "20%") {
            endAnchor = "top";
          } else if (challenge.position.top === "80%") {
            endAnchor = "bottom";
          } else if (challenge.position.left === "15%") {
            endAnchor = "left";
          } else if (challenge.position.left === "85%") {
            endAnchor = "right";
          }

          return (
            <Xarrow
              key={challenge.id}
              start={`${challenge.id}`}
              end="center-node"
              color="#89B2FF"
              strokeWidth={2}
              headSize={0}
              curveness={0.9}
              path="smooth"
              endAnchor={endAnchor}
              middleLabel={
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#2563EB",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProcurementChallenges;
