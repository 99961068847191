import React from "react";
import UpdateNavbar from "../Common/UpdateNavbar";
import ProcurementChallenges from "./ProcurementChallenges";
import UpdateFooter from "../Common/UpdateFooter";
import UpdatePartners from "../Common/UpdatePartners";
import EnterpriseSolutions from "./EnterpriceSolutions";
import ConsultationSection from "./ConsultationSection";
import UpdateBanner from "./UpdateBanner";
import BazAIHelmet from "pages/Landing/common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";

export default function UpdateHomePage() {
  return (
    <div className="lg:pt-[72px]">
      <UpdateNavbar />
      <BazAIHelmet
        title="EkkBaz AI"
        description="Transcending Your Procurement Teams With Limitless AI Agents."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/`}
      />
      <UpdateBanner />
      <ProcurementChallenges />
      <ConsultationSection />
      <EnterpriseSolutions />
      <UpdatePartners />
      <UpdateFooter />
    </div>
  );
}
