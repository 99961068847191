import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import Benefits from "../common/Benefits";
import { inventoryBenefits } from "./inventoryBenefits";
import Partner from "../common/Partner";
import PricePlan from "../common/PricePlan";
import { inventoryData } from "./inventorydata";
import ProductFacilites from "../common/ProductFacilities";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import FacilitiesCard from "pages/UpdatedLanding/Common/FacilitiesCard";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function LandingForInventoryManagement() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      const currentPosition = document.documentElement.scrollTop;
      setScrolling(currentPosition > 150);
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="Inventory Management"
        description="Easily handle your inventory with AI Agent."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/inventory`}
      />
      {/* <BazAINavigation
				scrolling={scrolling ? "isScrolling" : ""}
				from='mainLanding'
			/> */}
      <UpdateNavbar />

      <Banner
        headline="Inventory Management"
        bannerImage="/assets/images/inventory-banner.jpeg"
        from="health"
      />

      {/* <ProductFacilites facilities={inventoryData} from='food' /> */}
      <div className="my-10">
        <FacilitiesCard data={inventoryData} />
      </div>
      {/* <div className="my-16">
        <Partner />
      </div> */}
      {/* <PricePlan from='food' /> */}
      {/* <FooterV2 /> */}
      <UpdateFooter />
    </>
  );
}
