import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import Benefits from "../common/Benefits";
import { arBenefits } from "./arBenefits";
import PricePlan from "../common/PricePlan";
import Partner from "../common/Partner";
import ProductFacilites from "../common/ProductFacilities";
import { arData } from "./ARdata";
import UpdateNavbar from "pages/UpdatedLanding/Common/UpdateNavbar";
import FacilitiesCard from "pages/UpdatedLanding/Common/FacilitiesCard";
import UpdateFooter from "pages/UpdatedLanding/Common/UpdateFooter";

export default function LandingForARAutomation() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      const currentPosition = document.documentElement.scrollTop;
      setScrolling(currentPosition > 150);
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <div className="bg-blue-50 min-h-screen">
      <BazAIHelmet
        title="AR Automation"
        description="AR Automation with AI Agent."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/ar`}
      />
      {/* <BazAINavigation
				scrolling={scrolling ? "isScrolling" : ""}
				from='mainLanding'
			/> */}
      <UpdateNavbar />

      <Banner
        // headline='Hotel'
        bannerImage="/assets/images/ar_banner.jpg"
        from="health"
        headline="AR Automation"
      />

      {/* <ProductFacilites facilities={arData} from='food' /> */}
      <div className="my-10">
        <FacilitiesCard data={arData} />
      </div>
      {/* <div className="my-16">
        <Partner />
      </div> */}
      {/* <PricePlan from='food' /> */}
      {/* <FooterV2 /> */}
      <UpdateFooter />
    </div>
  );
}
